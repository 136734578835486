import React from 'react';
import ImageGallery from 'react-image-gallery';
import { Navigation } from './navigation'
import { About } from './about'
import { Services } from './services'
import { Pricing } from './pricing'
import { Contact } from './contact'
import { images } from './images'
import "react-image-gallery/styles/css/image-gallery.css";
import SmoothScroll from 'smooth-scroll'

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
})

class App extends React.Component {
  render () {
	  return (
		<div className="App">
			<Navigation />
			<p>&nbsp;</p>
			<p>&nbsp;</p>
			<p>&nbsp;</p>
			<p>&nbsp;</p>
			<ImageGallery items={images}/>
			<About />
			<Services />
			<Pricing />
			<Contact />
		</div>
	  );
	}
}

export default App;
