export const Pricing = (props) => {
  return (
    <div id='pricing' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Prices</h2>
          <p>
			This is the fee for the photo shoot, which typically takes about 1-2 hours.
			Below are the prices for digital images, which are on top of the session fee.
			<br/><br/>
			<p><b>DIGITAL IMAGES</b></p>
			<table border="0" cellspacing="0" cellpadding="0" align="center">
				<tbody>
					<tr>
						<td align="left" valign="top" width="20%">Single image</td>
						<td align="left" valign="top" width="80%">$30</td>
					</tr>
					<tr>
						<td align="left" valign="top" width="20%">5 images</td>
						<td align="left" valign="top" width="80%">$130</td>
					</tr>
					<tr>
						<td align="left" valign="top" width="20%">10 images</td>
						<td align="left" valign="top" width="80%">$240</td>
					</tr>
					<tr>
						<td align="left" valign="top" width="20%">20 images</td>
						<td align="left" valign="top" width="80%">$450</td>
					</tr>
				</tbody>
			</table>
			<br/>
			<p>All digital images are provided on a DVD. You can print as many copies as you like of the images you pay for.</p>
          </p>
        </div>
      </div>
    </div>
  )
}
