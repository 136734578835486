export const Services = (props) => {
  return (
    <div id='services' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>My Services</h2>
          <p>
			From the anticipation of pregnancy to the first days of warm delirium after birth or the many years as your child grows,
			you don't want to miss a thing. Let me take care of capturing the joy, beauty and excitement of all of it.
			<br/><br/><br/>
			Whether it be you during pregnancy, your parents holding their brand new grandchild, or any milestone needing to be captured,
			I would be honoured to serve you.
			<br/><br/><br/>
			A photo shoot typically takes 1-2 hours. I can cater it to suit you and your child's needs, so you can record all the emotions
			and personality with more flexibility and style than department store studios are able to offer...but without the expensive price
			tag of some independent studios. I will work with you to set the tone with the right props and simple backdrops,
			and to create a set of portraits that is right for you.
			<br/><br/><br/>
			I hope to create an experience where you will want to come back again to capture all of life's celebrations.
			In fact, my maternity clients usually also arrange for another photo shoot with their newborn.
			<br/><br/><br/>
			In the meantime, I hope you enjoy <a href='#page-top' className='page-scroll'>some samples</a> of my work so far...
          </p>
        </div>
      </div>
    </div>
  )
}
