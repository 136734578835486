export const About = (props) => {
  return (
    <div id='about'>
      <div className='container'>
        <div className='row'>
          <div className='col-xs-12 col-md-6'>
            {' '}
            <img src='img/about.jpg' className='img-responsive' alt='' />{' '}
          </div>
          <div className='col-xs-12 col-md-6'>
            <div className='about-text'>
              <h2>About Me</h2>
              <p>
				As a mother of three children, I know the importance of catching all the unique memories and
				personalities in the life of a child.For over a decade, I have loved photography.
				I get energized from taking pictures, and years of being there at the right moment with my own
				family has given me the expertise not to miss a thing.
				<br/><br/><br/>
				I live in Richmond, BC, Canada, and would love to share in and try to capture your memorable
				occasions as you welcome a new being into this world and into your life.
			  </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
