export const Contact = (props) => {
  return (
    <div id='contact' className='text-center'>
      <div className='container'>
	  {/* <div className='col-md-3 col-md-offset-1 contact-info'> */}
	  <div className='contact-item'>
	  <h2>Contact Info</h2>
            </div>
      </div>
	  <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-phone'></i> Phone
                </span>{' '}
                604-787-2494
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-envelope-o'></i> Email
                </span>{' '}
                contact@arezouphotography.ca
              </p>
            </div>
    </div>
  )
}
